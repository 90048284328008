@import url('https://fonts.googleapis.com/css2?family=Lora:wght@500;700&display=swap');     /* import Lora fonts (Google Fonts) */
@import url("https://use.typekit.net/agn0giy.css");                                     /* import Lato fonts (Adobe Fonts) */


/* COLORS */
:root {
    --red: #FF5732;
    --pink: #FBE6DB;
    --blue: #65BABF;
    --yellow: #FFD754;
    --navy: #0C2B4A;
    --cream: #FAF0E3;
}

.red {
    color: var(--red);
}


/* TEXT STYLES */
h1 {
    font-family: Lora;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 56px;
}

h2 {
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
}

h3 {
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
}

body {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
}

a {
    text-decoration: none;
    color: inherit;
}

html, body {
    max-width: 100%;
    overflow-x: hidden;
}
