@import url(https://fonts.googleapis.com/css2?family=Lora:wght@500;700&display=swap);
@import url(https://use.typekit.net/agn0giy.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* import Lora fonts (Google Fonts) */                                     /* import Lato fonts (Adobe Fonts) */


/* COLORS */
:root {
    --red: #FF5732;
    --pink: #FBE6DB;
    --blue: #65BABF;
    --yellow: #FFD754;
    --navy: #0C2B4A;
    --cream: #FAF0E3;
}

.red {
    color: #FF5732;
    color: var(--red);
}


/* TEXT STYLES */
h1 {
    font-family: Lora;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 56px;
}

h2 {
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
}

h3 {
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
}

body {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
}

a {
    text-decoration: none;
    color: inherit;
}

html, body {
    max-width: 100%;
    overflow-x: hidden;
}

#navbar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    width: 100%;
    height: 110px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#navbar.navbar-color {
    background: linear-gradient(180deg, #FBE6DB 66.36%, rgba(251, 230, 219, 0) 100%);
    transition: background-color 200ms linear;
}

#navbar-title {
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 41px;

    color: #FF5732;
}

#navbar-title.navbar-title.hidden {
    visibility: hidden;
    opacity: 0;
}

#navbar-title.navbar-title.visible {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 700ms;
}

#navbar #contents {
    width: inherit;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.navbar-text {
    font-family: Lato;
    font-style: italic;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 10px 40px 40px;
}

@keyframes bg-change {
    from {opacity: 1;}
    to {opacity: 0;}
  }

#landing {
    width: 100vw;
    height: 100vh;
    position: relative;
}

#bg1, #bg2 {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    z-index: -1000;

    animation-name: bg-change;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

#bg1 {
    background: linear-gradient(279.79deg, #F6D011 -17.69%, rgba(255, 255, 255, 0) 79.08%), linear-gradient(195.44deg, #FF4E4E 3.68%, rgba(255, 255, 255, 0) 94.87%), #FAF0E3;
    animation-direction: alternate;
}

#bg2 {
    background: linear-gradient(279.79deg, #65BABF -17.69%, rgba(255, 215, 84, 0) 79.08%), linear-gradient(195.44deg, rgba(255, 255, 255, 0) 4.45%, #FFD754 94.87%), #FAF0E3;
    animation-direction: alternate-reverse;
}

#landing #header {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;

    color: var(--red);
}

#landing #landing-title {
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 72px;
    line-height: 92px;
    text-align: center;
}

#landing #register-btn {
    background-color: #FF5732;
    color: white;
    font-size: 20px;
    margin-top: 10px;
    padding: 10px;
    border-radius: 4px;
}

#landing #social-media {
    position: absolute;
    left: 40px;
    bottom: 40px;
    display: flex;
    flex-direction: row;
    font-size: 22px;
    color: #0C2B4A;
}

#landing h2 {
    margin: 8px 0;
}

#about {
  width: 100vw;
  height: 100vh;
  background-color: var(--pink);
  position: relative;
}

.about-container {
  max-width: 900px;
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: center;
  align-content: center;
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
}

#about-text {
  width: 500px;
}

#about-chapel {
  width: 350px;
  max-width: 100%;
  border-radius: 15px;
}


@media only screen and (max-width: 400px) {
    #about {
        padding-bottom: 200px;
    }
}
#schedule {
    width: 100vw;
    min-height: 100vh;
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-image: url(/static/media/schedule-bg.f4c8340c.svg);
    background-repeat: no-repeat, repeat;
    background-position: bottom;
    background-color: var(--pink);
}

.schedule-container {
    max-width: 1000px;
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 300px;
}

.schedule-container .days {
    width: 100%;

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    justify-content: space-around;
}

.schedule-container .day {
    width: 40%;
    min-width: 300px;
    white-space: pre;
}

.schedule-container .day h3 {
    text-align: center;
    border-bottom: 2px solid var(--red);
    padding-bottom: 10px;
}

#blobs {
    overflow: hidden;
    width: 100%;
}

#blobs img {
    position: absolute;
    overflow-x: hidden;
}

#blobs #top-right {
    top: -150px;
    right: 0;
}

#blobs #top-left {
    top: 0;
    left: -150px;
}

#blobs #bottom-right {
    top: 250px;
    right: -50px;
    width: 250px;
    visibility: hidden;
}

@media only screen and (max-width: 400px) {
    #blobs #top-left {
        top: 550px;
        left: -150px;
    }

    #blobs #bottom-right {
        visibility: visible;
    }
}



#faq {
    width: 100vw;
    background-color: var(--cream);
    padding: 100px 0;
    background-image: url(/static/media/faq-bg.14ebcbe5.svg);
    background-repeat: no-repeat, repeat;
    background-size: 2500px;
    background-position: center;
}

#faq h2 {
    color: var(--red);
    margin-bottom: 0;
    font-size: 20px;
    line-height: 20px;
}

#faq p {
    margin-top: 10px;
    margin-bottom: 40px;
}

.faq-text {
    width: 70%;
    max-width: 1000px;
    margin: 0 auto 100px;
}

.faq-details {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
}

.faq-col, .faq-col {
    width: 400px;
}

.for-more {
    width: 100%;
    text-align: center;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.more-button {
    color: white;
    font-size: 22px;
    background-color: #FF5732;
    padding: 16px 32px;
    border-radius: 50px;
}

.more-button:hover {
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    .faq-col, .faq-col {
        width: 100%;
    }
    #faq {
        background-size: 1400px;
    }
}

@media only screen and (max-width: 400px) {
    #faq {
        background-size: 700px;
        background-position: bottom;
    }
}

#speakers {
    width: 100vw;
    background-color: var(--cream);
    padding: 10px 0 100px;
}

.speakers-container {
    max-width: 1200px;
    margin: 0px auto;
    width: 80%;
}

.keynote-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
}

.keynote-text {
    display: block;
    max-width: 800px;
}

.keynote-img {
    width: 350px;
}

.hans {
    left: 30px;
    position: relative;
}

.lava {
    right: 50px;
    position: relative;
}

.speaker-info {
    text-align: center;
    width: 100%;
}

.speaker-name {
    font-size: 18px;
    margin-bottom: 8px;
}

.speaker-title {
    font-size: 14px;
    line-height: 15px;
}
#purpose {
    width: 100vw;
    min-height: 100vh;
    background-color: var(--pink);
}

.purpose-container {
    max-width: 700px;
    width: 70%;
    margin: 50px auto 0;
}

#scroll-image {
    border-radius: 15px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    max-width: 100%;
}

.alice-carousel {
    margin-bottom: 50px;
}

.purpose-img {
    border-radius: 8px;
}

@media only screen and (max-width: 400px) {
    #purpose {
        padding-bottom: 100px;
    }
}
#sponsors {
    width: 100vw;
    background-color: var(--cream);
    padding: 10px 0 100px;
}

.sponsors-container {
    max-width: 1200px;
    margin: 0px auto;
    width: 80%;
}

.sponsor-logos {
    text-align: center;
    justify-content: space-between;
    vertical-align: middle;
}

.sponsor-logos img {
    margin: 40px;
}

@media only screen and (max-width: 400px) {
    .sponsor-title {
        margin-left: 10%;
    }

    .sponsors-container {
        width: 100%;
    }
}
